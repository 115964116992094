<template>
  <v-form
    ref="ticketCorrectiveActionForm"
    v-model.trim="formValid"
    lazy-validation
    v-on:submit.stop.prevent="ticketCorrectiveActionForm"
  >
    <v-container
      fluid
      class="white-background client-service-forms corrective-action-form"
      :class="{ 'pointer-events-none': serviceForm.is_filled }"
    >
      <!-- <div class="d-none">
      <v-text-field
        type="hidden"
        v-model="pageLoading"
        :rules="[validateRules.required(!pageLoading)]"
      ></v-text-field>
    </div> -->
      <v-row class="mx-4">
        <v-col md="12" class="pt-0">
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Corrective Action</label
              >
              <!-- <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="Corrective Action"
                flat
                color="cyan"
                v-model="attributes.corrective_action"
              ></v-text-field> -->
              <v-textarea
                v-model.trim="attributes.corrective_action"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Corrective Action"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-col>

            <v-col md="6">
              <v-layout>
                <v-spacer></v-spacer>
                <div>
                  <v-col md="2">
                    <v-checkbox
                      color="cyan"
                      hide-details
                      label="Project"
                      v-model="attributes.ProjectChecked"
                      class="m-0 p-0 mt-2"
                    ></v-checkbox>
                  </v-col>
                  <v-col md="2">
                    <v-checkbox
                      color="cyan"
                      hide-details
                      label="Service"
                      v-model="attributes.ServiceChecked"
                      class="m-0 p-0 mt-2"
                    ></v-checkbox>
                  </v-col>
                  <v-col md="2">
                    <v-checkbox
                      color="cyan"
                      hide-details
                      label="Emergency"
                      v-model="attributes.EmergancyChecked"
                      class="m-0 p-0 mt-2"
                    ></v-checkbox>
                  </v-col>
                </div>
              </v-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label class="font-weight-700 font-size-16 pl-2"
                >For Safemark Rm No:</label
              >
              <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="For Safemark Rm No"
                flat
                color="cyan"
                v-model="attributes.room_no"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <label class="font-weight-700 font-size-16 pl-2"
                >Date of Sticker</label
              >
              <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="Date of Sticker"
                flat
                color="cyan"
                v-model="attributes.date_of_Sticker"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <label class="font-weight-700 font-size-16 pl-2">Delivery</label>
              <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="Delivery"
                flat
                color="cyan"
                v-model="attributes.delivery"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Observations/Recommendations</label
              >
              <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="Observations/Recommendations"
                flat
                color="cyan"
                v-model="attributes.observations_recommendations"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Battery level</label
              >
              <v-text-field
                dense
                filled
                label=""
                solo
                placeholder="Battery level"
                flat
                color="cyan"
                v-model="attributes.battery_level"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Door locked after servicing</label
              >
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="DoorlockedAfterServicing"
                placeholder="Door locked after servicing"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.door_locked_after_servicing"
              >
              </v-autocomplete>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2">Warranty</label>
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="Warranty"
                placeholder="Warranty"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.warranty"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Service Agreement</label
              >
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="ServiceAgreementList"
                placeholder="Service Agreement"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.serviceagreement"
              >
              </v-autocomplete>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2"
                >Chargeable</label
              >
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="ChargeableList"
                placeholder="Chargeable"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.chargeable"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2">Monitor</label>
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="Monitor"
                placeholder="Monitor"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.monitor"
              >
              </v-autocomplete>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2">Follow up</label>
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="FollowUp"
                placeholder="Follow up"
                solo
                flat
                item-text="name"
                item-value="name"
                class="width-100"
                v-model="attributes.follow_up"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16 pl-2">Remarks</label>
              <v-textarea
                v-model.trim="attributes.remark"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Remarks"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-col>
          </v-row>

          <!--  <v-row>
            <v-col md="12">
              <v-checkbox
                color="cyan"
                hide-details
                label="Project"
                v-model="attributes.ProjectChecked"
                class="m-0 p-0 mt-2"
              ></v-checkbox>
            </v-col>
            <v-col md="12">
              <v-checkbox
                color="cyan"
                hide-details
                label="Service"
                v-model="attributes.ServiceChecked"
                class="m-0 p-0 mt-2"
              ></v-checkbox>
            </v-col>
            <v-col md="12">
              <v-checkbox
                color="cyan"
                hide-details
                label="Emergency"
                v-model="attributes.EmergancyChecked"
                class="m-0 p-0 mt-2"
              ></v-checkbox>
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { currentUser } from "@/core/services/jwt.service";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  name: "corrective-action",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      formValid: true,
      pageLoading: true,

      attributes: {
        room_no: null,
        date_of_Sticker: null,
        ProjectChecked: false,
        ServiceChecked: false,
        EmergancyChecked: false,
        delivery: null,
        observations_recommendations: null,
        battery_level: null,
        door_locked_after_servicing: null,
        warranty: null,
        serviceagreement: null,
        chargeable: null,
        monitor: null,
        follow_up: null,
        remark: null,
        corrective_action: null,

        requested_by: null,
        complaints: null,
        equip_other: null,
        fault_found: null,
        follow_up_remark: null,
      },
      DoorlockedAfterServicing: [
        {
          id: 0,
          name: "Yes",
        },
        {
          id: 1,
          name: "No",
        },
      ],
      ServiceAgreementList: [
        {
          id: 0,
          name: "Full",
        },
        {
          id: 1,
          name: "Partial",
        },
        {
          id: 2,
          name: "NIL",
        },
      ],
      ChargeableList: [
        {
          id: 0,
          name: "Yes",
        },
        {
          id: 1,
          name: "No",
        },
        {
          id: 2,
          name: "TBA",
        },
      ],

      Warranty: [
        {
          id: 0,
          name: "Yes",
        },
        {
          id: 1,
          name: "No",
        },
      ],
      Monitor: [
        {
          id: 0,
          name: "Yes",
        },
        {
          id: 1,
          name: "No",
        },
      ],
      FollowUp: [
        {
          id: 0,
          name: "Yes",
        },
        {
          id: 1,
          name: "No",
        },
      ],
    };
  },
  methods: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
            console.log(_this.attributes, "_this.attributes");
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },

    saveServiceForm(action) {
      //alert(action)
      const _this = this;

      /* const formErrors = _this.validateForm(_this.$refs.ticketCorrectiveActionForm);
      alert(44)

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      } */

      const formData = _this.attributes;
      // console.log(formData, "rrrrrrrr");
      // alert(44)
      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: formData, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
          ServiceFormEventBus.$emit("refresh", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },

    /* saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    }, */
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      console.log(param, "ervice-form");
      _this.saveServiceForm(param);
    });

    _this.updatePreInformation();
  },
};
</script>
