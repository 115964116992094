var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"white-background client-service-forms service-report-form",class:{ 'pointer-events-none': _vm.serviceForm.is_filled },attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.attributes.addMore),function(row,index){return _c('v-col',{key:index,staticClass:"mb-3",staticStyle:{"border":"1px solid darkgrey"},attrs:{"md":"12"}},[(index > 0)?_c('v-col',{staticClass:"text-end pb-0",attrs:{"md":"12"}},[_c('div',{},[_c('v-btn',{staticClass:"custom-bold-button white--text v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default red",class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },on:{"click":function($event){return _vm.deleteSupervisorAction(index, _vm.attributes.addMore)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("ITEMS/PARTS")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","solo":"","placeholder":"ITEMS/PARTS","flat":"","color":"cyan"},model:{value:(row.items_parts),callback:function ($$v) {_vm.$set(row, "items_parts", $$v)},expression:"row.items_parts"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("SERIAL NO/MODEL")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","solo":"","placeholder":"SERIAL NO/MODEL","flat":"","color":"cyan"},model:{value:(row.serial_no_model),callback:function ($$v) {_vm.$set(row, "serial_no_model", $$v)},expression:"row.serial_no_model"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("UNIT PRICE")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","type":"number","solo":"","placeholder":"UNIT PRICE","flat":"","color":"cyan"},on:{"keyup":function($event){return _vm.calculateTotal()}},model:{value:(row.unit_price),callback:function ($$v) {_vm.$set(row, "unit_price", $$v)},expression:"row.unit_price"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("QTY")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","solo":"","placeholder":"QTY","type":"number","flat":"","color":"cyan"},on:{"keyup":function($event){return _vm.calculateTotal()}},model:{value:(row.qty),callback:function ($$v) {_vm.$set(row, "qty", $$v)},expression:"row.qty"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"11"}}),_c('v-col',{staticClass:"text-end pb-0",attrs:{"md":"1"}},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default cyan",class:{ 'pointer-events-none': _vm.is_staff },on:{"click":function($event){return _vm.addMoreJobaction('addMoreJob')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("TOTAL")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","type":"number","solo":"","placeholder":"TOTAL","flat":"","color":"cyan"},model:{value:(row.total_remarks),callback:function ($$v) {_vm.$set(row, "total_remarks", $$v)},expression:"row.total_remarks"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("REMARKS")]),_c('v-text-field',{class:{
              'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff,
            },attrs:{"dense":"","filled":"","label":"","solo":"","placeholder":"REMARKS","flat":"","color":"cyan"},model:{value:(row.supervisor_remarks),callback:function ($$v) {_vm.$set(row, "supervisor_remarks", $$v)},expression:"row.supervisor_remarks"}})],1)],1)],1)}),1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("Parts taken from")]),_c('v-autocomplete',{staticClass:"width-100",class:{ 'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff },attrs:{"dense":"","filled":"","color":"cyan","item-color":"cyan","items":_vm.PartsTakenFrom,"placeholder":"Parts taken from","solo":"","flat":"","item-text":"name","item-value":"name"},model:{value:(_vm.attributes.parts_taken_from),callback:function ($$v) {_vm.$set(_vm.attributes, "parts_taken_from", $$v)},expression:"attributes.parts_taken_from"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-16 pl-2"},[_vm._v("Remark")]),_c('v-textarea',{class:{ 'pointer-events-none': _vm.serviceForm.is_filled || _vm.is_staff },attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Remark","solo":"","flat":"","row-height":"25"},model:{value:(_vm.attributes.remark),callback:function ($$v) {_vm.$set(_vm.attributes, "remark", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributes.remark"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }