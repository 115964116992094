<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <v-row>
      <v-col
        style="border: 1px solid darkgrey"
        class="mb-3"
        md="12"
        v-for="(row, index) in attributes.addMore"
        :key="index"
      >
        <v-col md="12" class="text-end pb-0" v-if="index > 0">
          <div class="">
            <v-btn
              v-on:click="deleteSupervisorAction(index, attributes.addMore)"
              class="custom-bold-button white--text v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default red"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
        <!-- <div class="d-none">
          <v-text-field
            type="hidden"
            v-model="pageLoading"
            :rules="[validateRules.required(!pageLoading)]"
          ></v-text-field>
        </div> -->
        <v-row>
          <v-col md="3">
            <label class="font-weight-700 font-size-16 pl-2">ITEMS/PARTS</label>
            <v-text-field
              dense
              filled
              label=""
              solo
              placeholder="ITEMS/PARTS"
              flat
              color="cyan"
              v-model="row.items_parts"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <label class="font-weight-700 font-size-16 pl-2"
              >SERIAL NO/MODEL</label
            >
            <v-text-field
              dense
              filled
              label=""
              solo
              placeholder="SERIAL NO/MODEL"
              flat
              color="cyan"
              v-model="row.serial_no_model"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <label class="font-weight-700 font-size-16 pl-2">UNIT PRICE</label>

            <!-- <v-text-field
                  label="Rate"
                  dense
                  filled
                  solo
                  flat
                  :readonly="!canUpdate"
                  v-on:keyup="calculateTotal(index, 'selected-line-item')"
                  v-on:change="
                    save_enabled = true;
                    calculateTotal(index, 'selected-line-item');
                  "
                  type="number"
                  color="cyan"
                  v-model="line_item.rate"
                  v-model.trim.number.lazy="line_item.rate"
                  v-on:keypress="limitDecimal($event, line_item.rate)"
                ></v-text-field> -->

            <v-text-field
              dense
              filled
              label=""
              type="number"
              solo
              placeholder="UNIT PRICE"
              flat
              color="cyan"
              v-model="row.unit_price"
              v-on:keyup="calculateTotal()"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <label class="font-weight-700 font-size-16 pl-2">QTY</label>
            <v-text-field
              dense
              filled
              label=""
              v-on:keyup="calculateTotal()"
              solo
              placeholder="QTY"
              type="number"
              flat
              color="cyan"
              v-model="row.qty"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="11"> </v-col>
          <v-col md="1" class="text-end pb-0">
            <!--  <template v-if="!serviceFormInfo.is_filled">
                    <template
                      v-if="serviceFormInfo.service_form == 'supervisor-action'"
                    > -->
            <v-btn
              :class="{ 'pointer-events-none': is_staff }"
              v-on:click="addMoreJobaction('addMoreJob')"
              class="mx-2 custom-bold-button white--text v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default cyan"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <!-- </template>
                  </template> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6">
            <label class="font-weight-700 font-size-16 pl-2">TOTAL</label>
            <v-text-field
              dense
              filled
              label=""
              type="number"
              solo
              placeholder="TOTAL"
              flat
              color="cyan"
              v-model="row.total_remarks"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>

          <v-col md="6">
            <label class="font-weight-700 font-size-16 pl-2">REMARKS</label>
            <v-text-field
              dense
              filled
              label=""
              solo
              placeholder="REMARKS"
              flat
              color="cyan"
              v-model="row.supervisor_remarks"
              :class="{
                'pointer-events-none': serviceForm.is_filled || is_staff,
              }"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <label class="font-weight-700 font-size-16 pl-2"
          >Parts taken from</label
        >
        <v-autocomplete
          dense
          filled
          color="cyan"
          item-color="cyan"
          :items="PartsTakenFrom"
          placeholder="Parts taken from"
          solo
          flat
          item-text="name"
          item-value="name"
          class="width-100"
          v-model="attributes.parts_taken_from"
          :class="{ 'pointer-events-none': serviceForm.is_filled || is_staff }"
        >
        </v-autocomplete>
      </v-col>

      <v-col md="6">
        <label class="font-weight-700 font-size-16 pl-2">Remark</label>
        <v-textarea
          v-model.trim="attributes.remark"
          auto-grow
          dense
          filled
          :disabled="pageLoading"
          :loading="pageLoading"
          color="cyan"
          label="Remark"
          solo
          flat
          row-height="25"
          :class="{ 'pointer-events-none': serviceForm.is_filled || is_staff }"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import DateTimePicker from "@/view/pages/partials/DateTimePicker";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { currentUser } from "@/core/services/jwt.service";
export default {
  name: "work-order",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      attributes: {
        addMore: [
          {
            items_parts: null,
            serial_no_model: null,
            unit_price: null,
            qty: 1,
            total_remarks: null,
            supervisor_remarks: null,
          },
        ],
        remarks: null,
        parts_taken_from: null,
      },
      PartsTakenFrom: [
        {
          id: 0,
          name: "Client",
        },
        {
          id: 1,
          name: "stock",
        },
        {
          id: 2,
          name: "CSS",
        },
        {
          id: 3,
          name: "Inv",
        },
        {
          id: 4,
          name: "RWA",
        },
      ],
    };
  },
  components: {
    // DateTimePicker,
  },
  methods: {
    addMoreJobaction(param) {
      ServiceFormEventBus.$emit("addMoreJob", param);
    },

    calculateTotal() {
      const TotalRemarks = this.attributes.addMore.map((row) => {
        return {
          ...row,
          total_remarks: Number(row.unit_price * row.qty),
        };
      });
      this.attributes.addMore = TotalRemarks;
    },

    addMoreJob() {
      this.attributes.addMore.push({
        items_parts: null,
        serial_no_model: null,
        unit_price: null,
        qty: 1,
        total_remarks: null,
        //  remarks: null,
      });
    },

    deleteSupervisorAction(index) {
      this.attributes.addMore.splice(index, 1);
    },

    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      //console.log(param,"supervisor");
      _this.saveServiceForm(param);
    });

    ServiceFormEventBus.$on("addMoreJob", () => {
      _this.addMoreJob();

      ServiceFormEventBus.$on("addmorecounter", (param) => {
        _this.saveServiceForm(param);
      });
    });

    _this.updatePreInformation();
    _this.getOptions();
    _this.attributes = {
      addMore: [
        {
          items_parts: null,
          serial_no_model: null,
          unit_price: null,
          qty: 1,
          total_remarks: null,
        },
      ],
      remarks: null,
    };
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
  },
};
</script>
